import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import './styles.css';

const Rezerwacje = ({ rezerwacje, flota, kontrahenci, onAdd, onDelete }) => {
    const [form, setForm] = useState({
        odKiedy: '',
        doKiedy: '',
        autoId: '',
        kontrahentId: ''
    });
    const [open, setOpen] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        onAdd({ ...form, id: Date.now() });
        setForm({ odKiedy: '', doKiedy: '', autoId: '', kontrahentId: '' });
        setOpen(false);
    };

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Rezerwacje
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Rezerwację
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Dodaj Rezerwację</DialogTitle>
                <DialogContent>
                    <form
                        onSubmit={handleFormSubmit}
                        className="form-container"
                    >
                        <TextField name="odKiedy" label="Od Kiedy" type="date" value={form.odKiedy} onChange={handleInputChange} required className="textfield" InputLabelProps={{ shrink: true }} />
                        <TextField name="doKiedy" label="Do Kiedy" type="date" value={form.doKiedy} onChange={handleInputChange} required className="textfield" InputLabelProps={{ shrink: true }} />
                        <FormControl fullWidth required>
                            <InputLabel>Auto</InputLabel>
                            <Select
                                name="autoId"
                                value={form.autoId}
                                onChange={handleInputChange}
                                label="Auto"
                            >
                                {flota.map((pojazd) => (
                                    <MenuItem key={pojazd.id} value={pojazd.id}>
                                        {pojazd.model} - {pojazd.marka}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth required>
                            <InputLabel>Kontrahent</InputLabel>
                            <Select
                                name="kontrahentId"
                                value={form.kontrahentId}
                                onChange={handleInputChange}
                                label="Kontrahent"
                            >
                                {kontrahenci.map((kontrahent) => (
                                    <MenuItem key={kontrahent.id} value={kontrahent.id}>
                                        {kontrahent.imie} {kontrahent.nazwisko}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Dodaj
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper} className="table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Od Kiedy</TableCell>
                            <TableCell>Do Kiedy</TableCell>
                            <TableCell>Auto</TableCell>
                            <TableCell>Kontrahent</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rezerwacje.map((rezerwacja) => (
                            <TableRow key={rezerwacja.id} className="table-row">
                                <TableCell>{rezerwacja.odKiedy}</TableCell>
                                <TableCell>{rezerwacja.doKiedy}</TableCell>
                                <TableCell>
                                    {flota.find(pojazd => pojazd.id === rezerwacja.autoId)?.model || 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {kontrahenci.find(kontrahent => kontrahent.id === rezerwacja.kontrahentId)?.imie || 'N/A'}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => onDelete(rezerwacja.id)} color="secondary">Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Rezerwacje;
