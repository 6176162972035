import { createTheme } from '@mui/material/styles';

// Tworzenie motywu z niestandardowym kolorem primary
const theme = createTheme({
    palette: {
        primary: {
            main: '#ec6b23', // Kolor primary
        },
        secondary: {
            main: '#ff5722', // Kolor secondary (przykład)
        },
    },
    typography: {
        // Możesz tutaj dodać niestandardowe style typografii
    },
    components: {
        // Możesz tutaj dostosować style komponentów
        MuiButton: {
            styleOverrides: {
                root: {
                    // Stylowanie przycisków
                },
            },
        },
        // Inne komponenty
    },
});

export default theme;
