import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme'; // Ścieżka do pliku motywu
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, CssBaseline, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './components/Sidebar';
import Kontrahent from './components/Kontrahent';
import Flota from './components/Flota';
import Rezerwacje from './components/Rezerwacje';
import Grafik from './components/Grafik';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
    const [kontrahenci, setKontrahenci] = useState([]);
    const [flota, setFlota] = useState([]);
    const [rezerwacje, setRezerwacje] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const handleLogin = (status) => {
        setIsAuthenticated(status);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
    };

    const handleAddKontrahent = (newKontrahent) => {
        setKontrahenci([...kontrahenci, newKontrahent]);
    };

    const handleEditKontrahent = (updatedKontrahent) => {
        setKontrahenci(kontrahenci.map(k =>
            k.id === updatedKontrahent.id ? updatedKontrahent : k
        ));
    };

    const handleDeleteKontrahent = (id) => {
        setKontrahenci(kontrahenci.filter(k => k.id !== id));
    };

    const handleAddFlota = (newPojazd) => {
        setFlota([...flota, newPojazd]);
    };

    const handleEditFlota = (updatedPojazd) => {
        setFlota(flota.map(p =>
            p.id === updatedPojazd.id ? updatedPojazd : p
        ));
    };

    const handleDeleteFlota = (id) => {
        setFlota(flota.filter(k => k.id !== id));
    };
    
    const handleAddRezerwacja = (newRezerwacja) => {
        setRezerwacje([...rezerwacje, newRezerwacja]);
    };

    const handleEditRezerwacja = (updatedRezerwacja) => {
        setRezerwacje(rezerwacje.map(r =>
            r.id === updatedRezerwacja.id ? updatedRezerwacja : r
        ));
    };

    const handleDeleteRezerwacja = (id) => {
        setRezerwacje(rezerwacje.filter(r => r.id !== id));
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    {isAuthenticated && (
                        <Sidebar handleLogout={handleLogout} isOpen={isOpen} toggleSidebar={toggleSidebar} />
                    )}
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Toolbar>
                            {isAuthenticated && (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={toggleSidebar}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}
                        </Toolbar>
                        <Routes>
                            <Route path="/" element={<Navigate to={isAuthenticated ? "/grafik" : "/login"} />} />
                            <Route path="/login" element={<Login onLogin={handleLogin} />} />
                            <Route
                                path="/kontrahent"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Kontrahent
                                            kontrahenci={kontrahenci}
                                            onAdd={handleAddKontrahent}
                                            onEdit={handleEditKontrahent}
                                            onDelete={handleDeleteKontrahent}
                                        />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/flota"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Flota
                                            flota={flota}
                                            onAdd={handleAddFlota}
                                            onEdit={handleEditFlota}
                                            onDelete={handleDeleteFlota}
                                        />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/rezerwacje"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Rezerwacje
                                            kontrahenci={kontrahenci}
                                            flota={flota}
                                            onAdd={handleAddRezerwacja}
                                            onEdit={handleEditRezerwacja}
                                            onDelete={handleDeleteRezerwacja}
                                            rezerwacje={rezerwacje}
                                        />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/grafik"
                                element={
                                    <PrivateRoute isAuthenticated={isAuthenticated}>
                                        <Grafik rezerwacje={rezerwacje} flota={flota} kontrahenci={kontrahenci} />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="*" element={<Navigate to={isAuthenticated ? "/grafik" : "/login"} />} />
                        </Routes>
                    </Box>
                </Box>
            </Router>
        </ThemeProvider>
    );
};

export default App;
