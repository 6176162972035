import React from 'react';
import { Drawer, List, ListItem, ListItemText, Box, Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.png';  // Importowanie logo

const Sidebar = ({ handleLogout, isOpen, toggleSidebar }) => {
    return (
        <Drawer
            variant="persistent"
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                width: isOpen ? 240 : 0,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: isOpen ? 240 : 0,
                    boxSizing: 'border-box',
                    overflowX: 'hidden',
                    transition: 'width 0.3s ease-in-out',
                },
            }}
        >
            {/* Przycisk do chowania sidebara */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <IconButton onClick={toggleSidebar}>

                </IconButton>
            </Box>

            {/* Sekcja logo */}
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <img src={logo} alt="Logo" style={{ maxWidth: '100%' }} />
            </Box>

            {/* Sekcja z linkami */}
            <Box sx={{ flexGrow: 1 }}>
                <List>
                    <ListItem button component={Link} to="/kontrahent">
                        <ListItemText primary="Kontrahent" />
                    </ListItem>
                    <ListItem button component={Link} to="/flota">
                        <ListItemText primary="Flota" />
                    </ListItem>
                    <ListItem button component={Link} to="/rezerwacje">
                        <ListItemText primary="Rezerwacje" />
                    </ListItem>
                    <ListItem button component={Link} to="/grafik">
                        <ListItemText primary="Grafik" />
                    </ListItem>
                    <ListItem button component={Link} to="/">
                        <ListItemText primary="Płatności" />
                    </ListItem>
                    <ListItem button component={Link} to="/">
                        <ListItemText primary="Wydanie samochodu" />
                    </ListItem>
                    <ListItem button component={Link} to="/">
                        <ListItemText primary="Magazyn" />
                    </ListItem>
                    <ListItem button component={Link} to="/x">
                        <ListItemText primary="Historia" />
                    </ListItem>
                    <ListItem button component={Link} to="/x">
                        <ListItemText primary="Placeholder" />
                    </ListItem>
                </List>
            </Box>

            {/* Sekcja przycisku wylogowania */}
            <Box sx={{ p: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<LogoutIcon />}
                    onClick={handleLogout}
                    fullWidth
                >
                    Wyloguj
                </Button>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
