import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        // Sprawdzenie z twardo zakodowanymi danymi
        if (username === 'admin' && password === 'admin1') {
            onLogin(true);
            navigate('/grafik'); // Przekierowanie po zalogowaniu
        } else {
            alert('Nieprawidłowy login lub hasło');
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
            <Typography variant="h4" gutterBottom>Logowanie</Typography>
            <TextField
                label="Nazwa użytkownika"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
            />
            <TextField
                label="Hasło"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleLogin}>
                Zaloguj
            </Button>
        </Box>
    );
};

export default Login;
