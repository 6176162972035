import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import './styles.css'; // Import the CSS file

const Kontrahent = ({ kontrahenci, onAdd, onDelete, onEdit }) => {
    const [form, setForm] = useState({
        imie: '',
        nazwisko: '',
        pesel: '',
        nip: ''
    });
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (isEditMode) {
            onEdit({ ...form, id: editId });
        } else {
            onAdd({ ...form, id: Date.now() });
        }
        setForm({ imie: '', nazwisko: '', pesel: '', nip: '' });
        setOpen(false);
        setIsEditMode(false);
        setEditId(null);
    };

    const handleEditClick = (kontrahent) => {
        setForm(kontrahent);
        setEditId(kontrahent.id);
        setIsEditMode(true);
        setOpen(true);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredKontrahenci = kontrahenci.filter((kontrahent) =>
        kontrahent.imie.toLowerCase().includes(searchTerm) ||
        kontrahent.nazwisko.toLowerCase().includes(searchTerm) ||
        kontrahent.pesel.toLowerCase().includes(searchTerm) ||
        kontrahent.nip.toLowerCase().includes(searchTerm)
    );

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Kontrahent
            </Typography>
            <TextField
                label="Szukaj"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleSearchChange}
                value={searchTerm}
                className="search-field"
            />
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Kontrahenta
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{isEditMode ? 'Edytuj Kontrahenta' : 'Dodaj Kontrahenta'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit} className="form-container">
                        <TextField
                            name="imie"
                            label="Imię"
                            value={form.imie}
                            onChange={handleInputChange}
                            required
                            className="textfield"
                        />
                        <TextField
                            name="nazwisko"
                            label="Nazwisko"
                            value={form.nazwisko}
                            onChange={handleInputChange}
                            required
                            className="textfield"
                        />
                        <TextField
                            name="pesel"
                            label="PESEL"
                            value={form.pesel}
                            onChange={handleInputChange}
                            required
                            className="textfield"
                        />
                        <TextField
                            name="nip"
                            label="NIP"
                            value={form.nip}
                            onChange={handleInputChange}
                            required
                            className="textfield"
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false); setIsEditMode(false); setForm({ imie: '', nazwisko: '', pesel: '', nip: '' }); }} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {isEditMode ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper} className="table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Imię</TableCell>
                            <TableCell>Nazwisko</TableCell>
                            <TableCell>PESEL</TableCell>
                            <TableCell>NIP</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredKontrahenci.map((kontrahent) => (
                            <TableRow key={kontrahent.id} className="table-row">
                                <TableCell>{kontrahent.imie}</TableCell>
                                <TableCell>{kontrahent.nazwisko}</TableCell>
                                <TableCell>{kontrahent.pesel}</TableCell>
                                <TableCell>{kontrahent.nip}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(kontrahent)} color="primary">Edytuj</Button>
                                    <Button onClick={() => onDelete(kontrahent.id)} color="secondary">Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Kontrahent;
