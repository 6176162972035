import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import './styles.css'; // Import the CSS file

const Flota = ({ flota, onAdd, onEdit, onDelete }) => {
    const [form, setForm] = useState({
        model: '',
        marka: '',
        vin: '',
        kwotaWynajmu: '',
        cenaKupna: '',
        przeglad: '',
        ubezpieczenie: '',
        numerPolisy: '',
        daneUbezpieczyciela: '',
        oc: false,
        ac: false,
        nw: false,
        historiaSerwisowa: ''
    });
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (editMode) {
            onEdit({ ...form, id: editId });
        } else {
            onAdd({ ...form, id: Date.now() });
        }
        setForm({
            model: '',
            marka: '',
            vin: '',
            kwotaWynajmu: '',
            cenaKupna: '',
            przeglad: '',
            ubezpieczenie: '',
            numerPolisy: '',
            daneUbezpieczyciela: '',
            oc: false,
            ac: false,
            nw: false,
            historiaSerwisowa: ''
        });
        setOpen(false);
        setEditMode(false);
        setEditId(null);
    };

    const handleEditClick = (pojazd) => {
        setForm(pojazd);
        setEditId(pojazd.id);
        setEditMode(true);
        setOpen(true);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredFlota = flota.filter((pojazd) =>
        pojazd.model.toLowerCase().includes(searchTerm) ||
        pojazd.marka.toLowerCase().includes(searchTerm) ||
        pojazd.vin.toLowerCase().includes(searchTerm) ||
        pojazd.kwotaWynajmu.toLowerCase().includes(searchTerm) ||
        pojazd.cenaKupna.toLowerCase().includes(searchTerm) ||
        pojazd.przeglad.toLowerCase().includes(searchTerm) ||
        pojazd.ubezpieczenie.toLowerCase().includes(searchTerm) ||
        pojazd.numerPolisy.toLowerCase().includes(searchTerm) ||
        pojazd.daneUbezpieczyciela.toLowerCase().includes(searchTerm) ||
        pojazd.oc.toString().toLowerCase().includes(searchTerm) ||
        pojazd.ac.toString().toLowerCase().includes(searchTerm) ||
        pojazd.nw.toString().toLowerCase().includes(searchTerm) ||
        pojazd.historiaSerwisowa.toLowerCase().includes(searchTerm)
    );

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Flota
            </Typography>
            <TextField
                label="Szukaj"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleSearchChange}
                value={searchTerm}
                className="search-field"
            />
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Pojazd
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{editMode ? 'Edytuj Pojazd' : 'Dodaj Pojazd'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit} className="form-container">
                        <TextField name="model" label="Model" value={form.model} onChange={handleInputChange} required className="textfield" />
                        <TextField name="marka" label="Marka" value={form.marka} onChange={handleInputChange} required className="textfield" />
                        <TextField name="vin" label="VIN" value={form.vin} onChange={handleInputChange} required className="textfield" />
                        <TextField name="kwotaWynajmu" label="Kwota Wynajmu" value={form.kwotaWynajmu} onChange={handleInputChange} required className="textfield" />
                        <TextField name="cenaKupna" label="Cena Kupna" value={form.cenaKupna} onChange={handleInputChange} required className="textfield" />
                        <TextField name="przeglad" label="Przegląd" type="date" value={form.przeglad} onChange={handleInputChange} required className="textfield" InputLabelProps={{ shrink: true }} />
                        <TextField name="ubezpieczenie" label="Ubezpieczenie" type="date" value={form.ubezpieczenie} onChange={handleInputChange} required className="textfield" InputLabelProps={{ shrink: true }} />
                        <TextField name="numerPolisy" label="Numer Polisy" value={form.numerPolisy} onChange={handleInputChange} required className="textfield" />
                        <TextField name="daneUbezpieczyciela" label="Dane Ubezpieczyciela" value={form.daneUbezpieczyciela} onChange={handleInputChange} required className="textfield" />
                        <FormControlLabel
                            control={<Checkbox checked={form.oc} onChange={handleInputChange} name="oc" />}
                            label="OC"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={form.ac} onChange={handleInputChange} name="ac" />}
                            label="AC"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={form.nw} onChange={handleInputChange} name="nw" />}
                            label="NW"
                        />
                        <TextField name="historiaSerwisowa" label="Historia Serwisowa" value={form.historiaSerwisowa} onChange={handleInputChange} required multiline rows={4} className="textfield" />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {editMode ? 'Zapisz zmiany' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper} className="table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell>Marka</TableCell>
                            <TableCell>VIN</TableCell>
                            <TableCell>Kwota Wynajmu</TableCell>
                            <TableCell>Cena Kupna</TableCell>
                            <TableCell>Przegląd</TableCell>
                            <TableCell>Ubezpieczenie</TableCell>
                            <TableCell>Numer Polisy</TableCell>
                            <TableCell>Dane Ubezpieczyciela</TableCell>
                            <TableCell>OC</TableCell>
                            <TableCell>AC</TableCell>
                            <TableCell>NW</TableCell>
                            <TableCell>Historia Serwisowa</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFlota.map((pojazd) => (
                            <TableRow key={pojazd.id} className="table-row">
                                <TableCell>{pojazd.model}</TableCell>
                                <TableCell>{pojazd.marka}</TableCell>
                                <TableCell>{pojazd.vin}</TableCell>
                                <TableCell>{pojazd.kwotaWynajmu}</TableCell>
                                <TableCell>{pojazd.cenaKupna}</TableCell>
                                <TableCell>{pojazd.przeglad}</TableCell>
                                <TableCell>{pojazd.ubezpieczenie}</TableCell>
                                <TableCell>{pojazd.numerPolisy}</TableCell>
                                <TableCell>{pojazd.daneUbezpieczyciela}</TableCell>
                                <TableCell>{pojazd.oc ? 'Tak' : 'Nie'}</TableCell>
                                <TableCell>{pojazd.ac ? 'Tak' : 'Nie'}</TableCell>
                                <TableCell>{pojazd.nw ? 'Tak' : 'Nie'}</TableCell>
                                <TableCell>{pojazd.historiaSerwisowa}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(pojazd)} color="primary">Edytuj</Button>
                                    <Button onClick={() => onDelete(pojazd.id)} color="secondary">Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Flota;
